// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDJ6jjiFFWyw3VGI8yiAnTA0CdHMGYCPQY",
  authDomain: "gasbill2nd.firebaseapp.com",
  databaseURL: "https://gasbill2nd-default-rtdb.firebaseio.com",
  projectId: "gasbill2nd",
  storageBucket: "gasbill2nd.appspot.com",
  messagingSenderId: "138039966544",
  appId: "1:138039966544:web:679d7459df12cc1c4e4a55",
  measurementId: "G-8YL7134EXH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };